import isEmpty from 'lodash/isEmpty';

export const getSuperVariantConfigName = (
  superVariantConfig = [],
  mealTypes = [],
  isShortName = true
) => {
  let superVariantConfigName = '';

  if (isEmpty(superVariantConfig)) return '';

  superVariantConfig.map(mealTypeIri => {
    const mealType = mealTypes.find(
      mealType => mealType?.['@id'] === mealTypeIri
    );

    if (mealType?.name || mealTypeIri?.name) {
      const shortName = mealTypeIri?.shortName || mealType?.shortName || '';
      const regularName = mealTypeIri?.name || mealType?.name;

      superVariantConfigName = `${superVariantConfigName} ${
        isShortName ? shortName : regularName
      }, `;
    }
  });

  return `(${superVariantConfigName.slice(
    0,
    superVariantConfigName.length - 2
  )})`;
};
