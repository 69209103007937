import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import BinIcon from '@assets/icons/BinIcon';
import EditIcon from '@assets/icons/EditIcon';
import AddressLineInfo from '@components/elements/AddressLineInfo';
import Collapse from '@components/elements/Collapse';
import InfoIconTooltip from '@components/elements/InfoIconTooltip';
import PriceWrapper from '@components/elements/PriceWrapper';
import Tooltip from '@components/elements/Tooltip';
import withMemoBasketMethods from '@components/HOC/withMemoBasketMethods';
import BASKET_PAYMENT_MODES from '@constants/basketPaymentModes';
import DIET_SETTINGS from '@constants/dietSettings';
import { selectTabs } from '@features/orderTabs/orderTabsSlice';
import {
  selectModuleConfigClientPanel,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';
import { useAppMode } from '@hooks/useAppConfigSelectors';
import { getSuperVariantConfigName } from '@services/SuperVariant.service';
import { isCMSPage } from '@utils/helpers';

import BasketDietName from './BasketDietName/BasketDietName';
import BasketDietDay from './BasketDietDay';

import 'tippy.js/themes/light-border.css';

const BasketDietConfig = dynamic(() => import('./BasketDiet/BasketDietConfig'));
const BasketDietOneTimePayment = dynamic(() =>
  import('./BasketDiet/BasketDietOneTimePayment')
);
const BasketDietSubscriptionPayment = dynamic(() =>
  import('./BasketDiet/BasketDietSubscriptionPayment')
);

const BasketDiet = ({
  diet,
  // context
  showBinButton = true,
  showEditButton = true,
  showAddressLine = true,
  showBorderBottom = true,
  showDietNameInput = true,
  isExpeandedByDefault = true,
  showDietConnectedElements = true,
  activeOrderDietIri,
  basketItems,
  handleRemoveBasketDiet,
  setActiveOrderDietIri,
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();

  const { isDietShop } = useAppMode();
  const [isExpanded, toggleDiet] = useState(isExpeandedByDefault);
  const [activeDietSettingEditing, setActiveDietSettingEditing] =
    useState(null);
  const { currentStepIndex, stepsCount } = useSelector(selectTabs);
  const { allowClientOwnDietName } = useAppConfigSelector(
    selectModuleConfigClientPanel
  );
  const canDeleteDiet =
    !(activeOrderDietIri === diet['@id']) &&
    (basketItems?.dietElements ?? []).length > 1;

  const dietConnectedElements = diet?.connectedElements ?? {};
  const isLastStep = currentStepIndex === stepsCount - 1;

  const isOneDiet = basketItems.dietElements.length === 1;

  const isSubscriptionPayment =
    diet?.paymentMode === BASKET_PAYMENT_MODES.SUBSCRIPTION_PAYMENT;

  const sumConnectedElements = Object.values(
    diet?.connectedElements ?? {}
  ).reduce(
    (acc, days) => {
      const dayPrice = Object.values(days).reduce(
        (acc, { price }) => {
          return {
            ...acc,
            afterDiscount: acc.afterDiscount + price.afterDiscount,
            beforeDiscount: acc.beforeDiscount + price.beforeDiscount,
          };
        },
        { afterDiscount: 0, beforeDiscount: 0 }
      );

      return {
        ...acc,
        afterDiscount: acc.afterDiscount + dayPrice.afterDiscount,
        beforeDiscount: acc.beforeDiscount + dayPrice.beforeDiscount,
      };
    },
    { afterDiscount: 0, beforeDiscount: 0 }
  );

  const isSuperVariant = Boolean(diet.mealTypes.length);

  const superVariantConfigName = getSuperVariantConfigName(
    (diet?.mealTypes ?? []).map(mealType => mealType?.['@id']),
    diet?.mealTypes ?? []
  );

  return (
    <div
      css={[
        !isLastStep &&
          activeOrderDietIri === diet['@id'] &&
          tw`border-2 border-primary rounded-md`,
      ]}
    >
      <motion.header initial={false} onClick={() => toggleDiet(!isExpanded)}>
        <div
          css={[
            tw`px-2 py-1 text-sm font-semibold bg-gray-0 border-2 border-transparent rounded-md cursor-pointer mx-0.5`,
          ]}
        >
          <div tw="flex mb-2 leading-snug">
            <div css={!isOneDiet && tw`w-3/4`} tw="flex-grow">
              {diet?.diet?.name && (
                <p
                  data-cy="basket__current-diet-name"
                  tw="capitalize flex items-center mb-2"
                >
                  {diet.diet.name}
                </p>
              )}

              <p tw="mb-2 leading-snug" data-cy="basket__current-diet-variant">
                {`${diet?.variant?.name ?? ''} ${superVariantConfigName ?? ''}`}
              </p>

              {diet?.calorific?.name && (
                <p
                  tw="mb-2 leading-snug"
                  data-cy="basket__current-diet-variant"
                >
                  {diet.calorific.name}
                  {diet.calculatedCalorific && isSuperVariant && (
                    <span tw="inline-block mx-1 -mt-0.5 translate-y-0.5">
                      <InfoIconTooltip
                        tw="text-center"
                        maxWidth={200}
                        content={t(
                          '$*components.basketDiet.calculatedCaloriifc',
                          { kcal: diet.calculatedCalorific }
                        )}
                      />
                    </span>
                  )}
                </p>
              )}
            </div>

            <div
              css={!isOneDiet && tw`w-1/4`}
              tw="flex justify-end ml-2 mr-[-5px]"
            >
              <Fragment>
                {showEditButton &&
                  !isCMSPage(router.pathname) &&
                  activeOrderDietIri !== null &&
                  activeOrderDietIri !== diet['@id'] && (
                    <Tooltip
                      content={
                        <div>
                          {t(
                            '$*components.basketDiet.editDiet',
                            'Edytuj dietę'
                          )}
                        </div>
                      }
                    >
                      <span>
                        <EditIcon
                          tw="cursor-pointer w-4 mx-1"
                          onClick={e => {
                            e.stopPropagation();
                            dispatch(setActiveOrderDietIri(diet['@id']));
                          }}
                        />
                      </span>
                    </Tooltip>
                  )}

                {showBinButton && canDeleteDiet && (
                  <Tooltip
                    content={
                      <div>
                        {t('$*components.basketDiet.removeDiet', 'Usuń dietę')}
                      </div>
                    }
                  >
                    <span>
                      <BinIcon
                        tw="cursor-pointer w-4 mx-1"
                        onClick={e => {
                          e.stopPropagation();
                          handleRemoveBasketDiet(diet?.['@id']);
                        }}
                      />
                    </span>
                  </Tooltip>
                )}
              </Fragment>

              <FontAwesomeIcon
                icon={isExpanded ? faChevronUp : faChevronDown}
                tw="mx-1"
              />
            </div>
          </div>
          <div tw="flex justify-between">
            <span data-cy="basket__current-diet-variant">{`${
              diet.dietLength
            } ${t('$*basket.CountDayInformation', 'dni')}`}</span>

            {isDietShop && showDietConnectedElements && !isExpanded ? (
              <PriceWrapper
                afterDiscount={
                  diet?.price?.afterDiscount +
                    sumConnectedElements?.afterDiscount ?? 0
                }
                beforeDiscount={
                  diet?.price?.beforeDiscount +
                    sumConnectedElements?.beforeDiscount ?? 0
                }
              />
            ) : (
              <PriceWrapper
                afterDiscount={diet?.price?.afterDiscount ?? 0}
                beforeDiscount={diet?.price?.beforeDiscount ?? 0}
              />
            )}
          </div>
        </div>
      </motion.header>

      <Collapse
        isExpanded={isExpanded}
        styles={[
          tw`px-2`,
          activeDietSettingEditing !== DIET_SETTINGS.ORDER_TYPE &&
            tw`overflow-hidden`,
        ]}
      >
        {isLastStep && (
          <>
            {showDietNameInput && allowClientOwnDietName && (
              <div tw="pt-3">
                <BasketDietName
                  existingItem={diet?.['@id']}
                  name={diet?.name}
                />
              </div>
            )}
            {showAddressLine && !isEmpty(diet?.address) && (
              <div tw="text-sm my-2">
                <p tw="font-bold my-0 text-primary">
                  {t(
                    '$*components.basketDiet.deliveryAddress',
                    'Adres dostawy'
                  )}
                </p>
                <AddressLineInfo {...diet?.address} />
              </div>
            )}
          </>
        )}

        {isSubscriptionPayment ? (
          <BasketDietSubscriptionPayment
            diet={diet}
            showBorderBottom={showBorderBottom}
          />
        ) : (
          <BasketDietOneTimePayment
            diet={diet}
            fontSize="sm"
            showBorderBottom={showBorderBottom}
          />
        )}

        {isCMSPage(router.pathname) && (
          <BasketDietConfig
            diet={diet}
            activeDietSettingEditing={activeDietSettingEditing}
            setActiveDietSettingEditing={setActiveDietSettingEditing}
          />
        )}

        {showDietConnectedElements &&
          !isEmpty(dietConnectedElements) &&
          Object.entries(dietConnectedElements).map(([dietIri, days]) => (
            <BasketDietDay
              key={dietIri}
              connectedToIri={diet['@id']}
              days={days}
              dietIri={dietIri}
            />
          ))}
      </Collapse>
    </div>
  );
};

export default withMemoBasketMethods(BasketDiet, [
  { as: 'basketItems', path: 'basketStore.items' },
  { as: 'activeOrderDietIri', path: 'basketStore.activeOrderDietIri' },
  'handleRemoveBasketDiet',
  'setActiveOrderDietIri',
]);
