import { useTranslation } from 'next-i18next';

import { Fragment } from 'react';

import {
  selectModuleConfigClientPanel,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';

const AddressLineInfo = ({
  street,
  buildNumber,
  placeNumber,
  postCode,
  city,
  mode = 'ALL',
}) => {
  const { t } = useTranslation();
  const { useAddressesWithLessFields } = useAppConfigSelector(
    selectModuleConfigClientPanel
  );

  const isCityFirstNoPostcodeMode = mode === 'CITY_FIRST_NO_POSTCODE';

  if (useAddressesWithLessFields) {
    const [addressLine1 = '', addressLine2 = ''] = street?.split('; ') ?? [
      '',
      '',
    ];

    return (
      <Fragment>
        <div>{isCityFirstNoPostcodeMode ? city : `${postCode} ${city}`}</div>
        <div>{addressLine1}</div>
        <div>{addressLine2}</div>
      </Fragment>
    );
  }

  if (isCityFirstNoPostcodeMode) {
    return (
      <Fragment>
        <div>{city}</div>
        <div>
          {[
            street,
            !['0', 0].includes(buildNumber) ? buildNumber : '',
            placeNumber
              ? `${t(
                  '$*components.addressesLineInfo.premisesShort',
                  'lok.'
                )} ${placeNumber}`
              : '',
          ].join(' ')}
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div>
        {[
          street,
          !['0', 0].includes(buildNumber) ? buildNumber : '',
          placeNumber
            ? `${t(
                '$*components.addressesLineInfo.premisesShort',
                'lok.'
              )} ${placeNumber}`
            : '',
        ].join(' ')}
      </div>
      <div>
        {postCode} {city}
      </div>
    </Fragment>
  );
};

AddressLineInfo.displayName = 'AddressLineInfo';

export default AddressLineInfo;
