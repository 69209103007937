import { useTranslation } from 'next-i18next';

import tw from 'twin.macro';
import { v4 as uuidv4 } from 'uuid';

import INPUT_IDS from '@constants/inputs/dietInputs';
import { cssMerge } from '@utils/styleHelpers';

const useFields = ({ styles } = {}) => {
  const { t } = useTranslation();

  const fields = [
    {
      id: INPUT_IDS.NAME,
      type: 'text',
      colCss: tw`relative`,
      className: 'input--user-diet-name',
      placeholder: t('$*input.userDietName.label', 'Wprowadź nazwę diety'),
      autoComplete: uuidv4(),
      spellCheck: false,
      maxLength: 255,
      formGroupProps: { styles: { mode: 'replace', css: tw`mb-0` } },
      styles: {
        css: cssMerge({
          defaultCss: tw`px-0 border-0 border-b rounded-none focus-visible:(ring-0 border-b-primary) text-sm pr-10`,
          ...styles,
        }),
      },
    },
  ];

  return fields;
};

export default useFields;
