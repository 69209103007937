import { useTranslation } from 'next-i18next';

import { Fragment } from 'react';
import { useDebounce } from 'react-use';
import { useFormikContext } from 'formik';

const AutoSave = ({ debounceMs, showText = true }) => {
  const { t } = useTranslation();
  const { values, submitForm, isSubmitting, dirty } = useFormikContext();

  useDebounce(
    () => {
      if (dirty) {
        submitForm();
      }
    },
    debounceMs,
    [values, dirty]
  );

  if (!showText) {
    return null;
  }

  return (
    <Fragment>
      {isSubmitting ? t('$*common.autosave', 'autozapis...') : null}
    </Fragment>
  );
};

export default AutoSave;
